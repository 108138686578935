// extracted by mini-css-extract-plugin
export var benefitsHolder = "bisolviralSprey-module--benefitsHolder--mj6Zu";
export var cureHolder = "bisolviralSprey-module--cureHolder--jJbcJ";
export var curiosities = "bisolviralSprey-module--curiosities--1Odxe";
export var effectholder = "bisolviralSprey-module--effectholder--WlMqZ";
export var flexholder = "bisolviralSprey-module--flexholder--Ve-4j";
export var holder = "bisolviralSprey-module--holder---DwiZ";
export var image = "bisolviralSprey-module--image--eKqLV";
export var protectedHolder = "bisolviralSprey-module--protectedHolder--ARAjI";
export var rrs__options = "bisolviralSprey-module--rrs__options--b41Dc";
export var smallHeading = "bisolviralSprey-module--smallHeading--Vxpnw";
export var whyHolder = "bisolviralSprey-module--whyHolder--+fwgt";