import * as React from "react";
import * as styles from "./bisolduoSprey.module.scss";
import Heading from "../../basic-components/heading/heading";
import { ProductProps } from "./types";
import { Level } from "react-accessible-headings";
import classNames from "classnames";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Button from "../../basic-components/button/button";
import { ButtonTypesEnum } from "../../basic-components/button/types";
import { Curiosities } from "../../curiosities/curiosities";

export const BisolduoSprey: React.FC<ProductProps> = ({
  item,
  location,
}: ProductProps) => {
  const {
    title,
    image: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;
  return (
    <div className={styles.holder}>
      <Heading id="neden">
        Soğuk algınlığı, öksürük ve boğaz tahrişinin ilk belirtilerinin
        tedavisi için
      </Heading>
      <Level>
        <section className={styles.whyHolder}>
          <Heading className="purple">
            BİSOLDUO ACTION SPREY - AĞIZ VE BOĞAZ SPREYİ
          </Heading>
          <Level>
            <Level>
              <p>
                Boğazda meydana gelen tahriş, boğaz ağrısı, kuru öksürük, ses
                kısıklığı, yutma ağrısı ve şişmiş lenf düğümleri soğuk
                algınlığının ilk belirtileridir.{" "}
                <b>SOĞUK ALGINLIĞI SEMPTOMLARINI </b>
                ilk andan itibaren <b>BİSOLDUO®</b> Action Sprey ile tedavi
                etmenizi öneriyoruz. Çünkü <b>BİSOLDUO®</b> Action Sprey
                kötüleşmesini önler, öksürüğü ve tahriş olmuş boğazı rahatlatır.
                <b>BİSOLDUO®</b> Action Sprey ayrıca boğaz ağrısı ve ses
                süresini de kısaltır.
              </p>
              <p id="faydalar">
                <b>BİSOLDUO®</b> Action Sprey ağız ve boğazdaki kuru ve tahriş
                dokunun tedavisine yönelik bir ağız ve boğaz spreyidir.
              </p>
              <div className={classNames("flexbox", styles.flexholder)}>
                <ul>
                  <li className="blue">
                    <b>BİSOLDUO® ACTION SPREY</b> akut farenjit ve/veya ağrısı,
                    ses kısıklığı, öksürük ve ağrılı yutma gibi belirtilerini
                    azaltmaktadır. Ayrıca kuru ve tahriş olmuş dokunun
                    nemlendirilmesini sağlar ve mukozanın bariyer yapısını
                    koruyarak yenilenmesini destekler.
                  </li>
                  <li className="blue">
                    <b>BİSOLDUO® ACTION SPREY</b> ağız ve boğazdaki mukozanın
                    enflamasyonunu azaltmayı destekler ve boğaz ağrısı veya ses
                    kısıklığı süresini azaltır.
                  </li>
                  <li className="blue">
                    <b>BİSOLDUO® ACTION SPREY</b> ses kısıklığı, kuru öksürük,
                    ve boğaz ağrısı gibi ilk belirtileri rahatlatır ve önler.
                  </li>
                  <li className="blue">
                    <b>BİSOLDUO® ACTION SPREY</b> enflamasyon azaltıcı ve mukoza
                    zarını sağlamlaştırıcı özellikleri ile doğal bir hücre
                    koruyucu molekül olan Ectoin® içermektedir.
                  </li>
                </ul>
                <StaticImage
                  className={styles.image}
                  src="../../../images/product-pages/woman@3x.png"
                  alt="Kadın görseli"
                  width={486}
                  height={486}
                />
              </div>
              <div className={styles.whatHolder}>
                <div className={classNames("flexbox", styles.flexholder)}>
                  <GatsbyImage
                    className={styles.image}
                    loading="lazy"
                    image={gatsbyImageData}
                    alt={title + " ürün görseli"}
                  />
                  <p className={classNames("extra-bold", styles.description)}>
                    Bal, hatmi kökü ekstresi ve Ectoin® boğazın tahriş olmuş
                    mukoza zarı üzerinde koruyucu bir film oluşturarak soğuk
                    algınlığı semptomlarının lokal olarak şiddetlenmesini önler.
                    Doğal Ectoin® molekülü ayrıca boğazdaki iltihabi reaksiyonu
                    önleyici ve sümük salgılayan zarları yatıştırıcı özelliklere
                    sahiptir.
                    <br />
                    <br />
                    <span className={styles.mobileHidden}>
                      <b>BİSOLDUO® ACTION SPREY</b> hoş bir bal ve hatmi tadı
                      olan alkolsüz bir ağız ve boğaz spreyidir. Etkisi doğal
                      maddelere dayalıdır.
                      <br />
                      <br />
                      Çok kullanışlı olan bu spreyin taşınması da kolaydır.
                    </span>
                  </p>
                </div>
              </div>
              <p className={styles.largeHidden}>
                <b>BİSOLDUO® ACTION SPREY</b> hoş bir bal ve hatmi tadı olan
                alkolsüz bir ağız ve boğaz spreyidir. Etkisi doğal maddelere
                <br />
                <br />
                Çok kullanışlı olan bu spreyin taşınması da kolaydır.
              </p>
            </Level>
          </Level>
        </section>
        <section className={styles.howToUseHolder}>
          <Level>
            <Heading id="dozaj" className="purple">
              Dozaj
            </Heading>
            <p>
              <b>BİSOLDUO® ACTION SPREY</b> soğuk algınlığı belirtilerinin
              tedavisine yöneliktir.
              <br />
              <br />
              <b>BİSOLDUO® ACTION SPREY</b>’in ses kısıklığı, kuru öksürük, ve
              boğaz ağrısı bulguları ilk görüldüğünde kullanılmaya başlanması
              önerilmektedir.
              <br />
              <br />
              <b>BİSOLDUO® ACTION SPREY</b> doktorunuz tarafından başka türlü
              önerilmedikçe ağza ve boğaza aşağıdaki şekilde uygulanmalıdır:
            </p>
            <div className={styles.caseHolder}>
              <div
                className={classNames(
                  "flexbox",
                  styles.flexHolder,
                  styles.flexList
                )}
              >
                <div className={classNames("flexbox", styles.content)}>
                  <Level>
                    <Heading>Yaş</Heading>
                  </Level>
                  <Level>
                    <Heading>Kullanım</Heading>
                  </Level>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <span className="blue">2-5 yaşındaki çocuklar</span>
                  <span className="blue">Günde 3 kez 1 defa püskürtülür.</span>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <span className="blue">6-11 yaşındaki çocuklar</span>
                  <span className="blue">Günde 3 kez 2 defa püskürtülür.</span>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <span className="blue">
                    Yetişkinler, 12 yaş ve üstü çocuklar
                  </span>
                  <span className="blue">
                    Günde birkaç kez 4 defa püskürtülür.
                  </span>
                </div>
              </div>
            </div>
          </Level>
        </section>
        <section className={styles.warningHolder}>
          <Level>
            <Heading className="purple">Uyarılar</Heading>
            <ul>
              <li className="blue">
                İki yaşın altındaki çocuklar BİSOLDUO® ACTION SPREY’i
                kullanmamalıdır.
              </li>
              <li className="blue">
                BİSOLDUO® ACTION SPREY Ectoin® veya ağız ve boğaz spreyinin
                içerdiği başka herhangi bir maddeye (hatmi, bal, potasyum
                sorbat, silikon dioksit, maltodekstrin) karşı aşırı duyarlılık
                durumunda kullanılmamalıdır.
              </li>
              <li className="blue">
                Fruktoz intoleransı (hassasiyeti) veya
                GlukozGalaktoz-Malabsorpsiyonu (yetersiz emilim) yaşıyorsanız
                BİSOLDUO® ACTION SPREY’i kullanmayın.
              </li>
              <li className="blue">
                Alerjik reaksiyonların görülmesi durumunda BİSOLDUO® ACTION
                SPREY kullanımı bir an önce kesilmelidir.
              </li>
              <li className="blue">
                Hijyeni sağlamak için her sprey şişesi yalnızca bir kişi
                tarafından kullanılmalıdır.
              </li>
              <li className="blue">
                Spreyi, şişe açıldıktan sonra 6 hafta içinde tüketiniz.
              </li>
              <li className="blue">
                BİSOLDUO® ACTION SPREY çocukların ulaşamayacağı yerde
                saklanmalıdır.
              </li>
              <li className="blue">
                Ürün hasar görmüş veya delinmiş ise kullanmayın.
              </li>
            </ul>
          </Level>
          <Button to={"/bisolduo-sprey.pdf"} type={ButtonTypesEnum.navyBorder}>
            KULLANIM KILAVUZU
          </Button>
        </section>
        <section className={styles.howHolder}>
          <Level>
            <Heading id="nasil" className="purple">
              BİSOLDUO® ACTION SPREY Kullanım Şekli
            </Heading>
            <p>
              BİSOLDUO® ACTION SPREY ‘i ilk kullanımdan önce kutuyu çalkalayın,
              kapağı çıkarın ve pompadaki havayı serbest bırakmak için 4 kez
              pompalayın. Sprey fonksiyonunun bozulmuş olması durumunda (şişe
              dik pozisyonda tutulmamışsa gerçekleşebilir), pompa mekanizmasına
              dik pozisyonda birkaç kez basın.
            </p>
          </Level>
          <ol>
            <li className="blue">Kutuyu çalkalayın, kapağı çıkarın.</li>
            <li className="blue">
              Şişeyi elinizde dik tutun ve işaret parmağınızı sprey başının
              üstüne yerleştirin.
            </li>
            <li className="blue">Ağzınızı açın.</li>
            <li className="blue">
              Spreyi ağzınızın önünde tutun ve spreyin başına hızlı ve sert bir
              şekilde basın. Lütfen ürünü uygulanması sırasında nefes almayın.
            </li>
            <li className="blue">Sprey başını ağzınızın dışında tutun.</li>
            <li className="blue">
              Cihazın ağzınızın iç kısmına veya dilinize değmesi durumunda
              şişenin değen ucunu temiz, tiftiksiz bir bez ile silin.
            </li>
            <li className="blue">Şişenin kapağını geri takın.</li>
          </ol>
        </section>
        <section id="sorular" className={styles.curiosities}>
          <Level>
            <Heading className="purple">Merak Edilenler</Heading>
          </Level>
          <Curiosities item={item} showCount={4} />
          <Button
            to={location.pathname + "merak-edilenler/"}
            type={ButtonTypesEnum.navyBorder}
          >
            TÜM SORULARI GÖRÜN
          </Button>
        </section>
        <div id="nereden" />
      </Level>
    </div>
  );
};

export default BisolduoSprey;
