import * as React from "react";
import { ProductHeaderProps } from "./types";
import * as productHeaderStyles from "./product-header.module.scss";
import Container from "../container/container";
import classNames from "classnames";
import { Breadcrumb } from "../breadcrumb/breadcrumb";
import Sticky from "react-stickynode";
import { GatsbyImage } from "gatsby-plugin-image";
import { Select } from "react-responsive-select";
import Arrow from "../../images/svgs/keyboard_arrow_down_black_24dp.svg";
import scrollTo from "gatsby-plugin-smoothscroll";

export const ProductHeader: React.FC<ProductHeaderProps> = ({
  item,
  location,
}: ProductHeaderProps) => {
  const {
    logo: { publicURL },
    whiteLogo,
    title,
    className,
    properties,
    sections,
    headerImage: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;
  const selectArray = sections.map((link) => ({
    value: link.path,
    text: link.text,
    inner: link.inner,
  }));
  return (
    <React.Fragment>
      {/* <Sticky innerZ="5" top=".header"> */}
      <div>
        <Breadcrumb pathname={location.pathname} lastItemTitle={item.title} />
      </div>
      {/* </Sticky> */}
      <Container fullWidth className="bg-orange lighten2">
        <Container>
          <div
            className={classNames(
              "flexbox",
              productHeaderStyles.component,
              productHeaderStyles[className]
            )}
          >
            <GatsbyImage
              className={productHeaderStyles.image}
              loading="lazy"
              image={gatsbyImageData}
              alt={title + " ürün görseli"}
            />
            <img
              className={productHeaderStyles.logo}
              src={publicURL}
              alt={title + "logo"}
              width="130"
              height="46"
            />
            <ul>
              {properties.map((property, i) => (
                <li key={i} className="blue">
                  {property}
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </Container>
      <Sticky innerZ="5" top={40} enableTransforms={false}>
        <Container
          fullWidth
          className={classNames("bg-blue-gradient", className)}
        >
          <Container className="productMenu">
            <div className="flexbox">
              <img src={whiteLogo.publicURL} style={{ height: 50 }} width="132" height="24" />
              <Select
                key="productMenu"
                name="productMenu"
                options={selectArray}
                caretIcon={<Arrow className="caret-icon" />}
                onChange={(newValue) => scrollTo(newValue.value)}
              />
            </div>
          </Container>
        </Container>
      </Sticky>
    </React.Fragment>
  );
};
