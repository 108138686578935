import * as React from "react";
import * as styles from "./bisolviralSprey.module.scss";
import Heading from "../../basic-components/heading/heading";
import { ProductProps } from "./types";
import { Level } from "react-accessible-headings";
import classNames from "classnames";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import {Curiosities} from "../../curiosities/curiosities";
import Button from "../../basic-components/button/button";
import {ButtonTypesEnum} from "../../basic-components/button/types";

export const BisolviralSprey: React.FC<ProductProps> = ({
  item,
  location,
}: ProductProps) => {
  const {
    title,
    image: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;
  return (
    <div className={styles.holder}>
      <Level>
        <section id="neden" className={styles.whyHolder}>
          <h1 className="purple">NEDEN Bisolviral?</h1>
          <Level>
            <Level>
              <div className={classNames("flexbox", styles.flexholder)}>
                <p>
                  Bisolviral, hastalık günlerinin sayısını %54’e veya 3,5 güne kadar,
                  soğuk algınlığı virüslerini %99’a kadar azaltır.
                  <br /><br />
                  Soğuk algınlığı virüslerine maruz kalındığında veya soğuk algınlığı
                  semptomlarının görüldüğü erken dönemde kullanılmalıdır.
                  <b><u>Bisolviral, yetişkinler ve 4 yaşın üzerindeki çocuklar için uygundur.</u></b>
                  <br /><br />
                  <b>Bisolviral</b>, şeker, gluten, laktoz ve koruyucu içermez.
                  <br /><br />
                  Bisolviral, gliserol, su, doğal bir enzim olan morina tripsini,
                  etanol (&#60;% 1), kalsiyum klorür, trometamol ve mentol içerir.
                  <br /><br />
                  4 yaşından itibaren tüm aile kullanımına uygundur.
                </p>
                <StaticImage
                  className={styles.image}
                  src="../../../images/product-pages/young-woman-covered-by-white-blanket-with-tea@3x.png"
                  alt="Kadın görseli"
                  width={384}
                  height={384}
                />
              </div>
            </Level>
          </Level>
        </section>
        <section id="fayda" className={styles.benefitsHolder}>
          <Level>
            <Heading className="purple">
              Bisolviral Nasıl Fayda Sağlar?
            </Heading>
            <p>
              <b>Bisolviral</b> soğuk algınlığı ve soğuk algınlığı semptomlarını,
              ÜSYE benzeri semptomları ve grip benzeri semptomları tedavi
              eder ve hafifletir.
            </p>
          </Level>
        </section>
        <section className={styles.protectedHolder}>
          <Level>
            <Heading className="purple">
              Soğuk Algınlığından Korunmada;
            </Heading>
            <ul>
              <li>Soğuk algınlığı virüslerine maruz kalındığında <b>en kısa sürede</b> başlanır.</li>
              <li><b>Hızlı</b> etkilidir.</li>
              <li><b>Viral yükü azaltır (%99).</b></li>
              <li><b>Soğuk algınlığı virüslerini</b>
                <ul>
                  <li><b>hapseder,</b></li>
                  <li><b>etkisiz hale getirir, yayılmasını ve çoğalmasını durdurur,</b></li>
                  <li><b>ağız boşluğunu ve boğazı korur.</b></li>
                </ul>
              </li>
              <li><b>Kolay</b> kullanılır.</li>
            </ul>
          </Level>
        </section>
        <section className={styles.cureHolder}>
          <Level>
            <Heading className="purple">
              Soğuk Algınlığının Tedavisinde;
            </Heading>
          </Level>
          <ul>
            <li>Soğuk algınlığı semptomları fark edildikten sonra <b>en kısa sürede</b> başlanır.</li>
            <li><b>Hızlı</b> etkilidir.</li>
            <li><b>Viral yükü azaltır (%99).</b></li>
            <li><b>Soğuk algınlığının</b>
              <ul>
                <li><b>süresini kısaltabilir (%56), </b></li>
                <li><b>semptomlarını hafifletir ve </b></li>
                <li><b>boğaz ağrısını yatıştırır.</b></li>
              </ul>
            </li>
            <li><b>Kolay</b> kullanılır.</li>
          </ul>
        </section>
        <section id="etki" className={styles.effectholder}>
          <Level>
            <Heading className="purple">
              Bisolviral Etki Mekanizması Nedir?
            </Heading>
            <div className={classNames("flexbox", styles.flexholder)}>
              <StaticImage
                className={styles.image}
                src="../../../images/bisolviral@3x.png"
                alt="Bisolviral Sprey"
              />
              <p>
                Ağız boşluğundaki mukoza zarını kaplayarak soğuk algınlığı virüslerine
                karşı koruyucu bariyer oluşturur. Bu bariyer, üst solunum yollarında
                epitel hücrelerinin enfeksiyonunu önlemek için Soğuk algınlığı virüslerini
                <b> hapseder, etkisiz hale getirir,</b> yayılmasını ve çoğalmasını durdurur,
                ağız boşluğunu ve boğazı <b>korur</b>.
              </p>
            </div>
          </Level>
        </section>
        <section
            id="merak"
            className={classNames(item.slug, styles.curiosities)}
        >
          <Heading>Merak Edilenler</Heading>
          <Curiosities item={item} showCount={4} />
          <Button
              to={location.pathname + "merak-edilenler/"}
              type={ButtonTypesEnum.navyBorder}
          >
            TÜM SORULARI GÖRÜN
          </Button>
        </section>
      </Level>
    </div>
  );
};

export default BisolviralSprey;
