// extracted by mini-css-extract-plugin
export var caseHolder = "bisolduoSprey-module--caseHolder--vAM13";
export var content = "bisolduoSprey-module--content--habD+";
export var curiosities = "bisolduoSprey-module--curiosities--ckAX4";
export var description = "bisolduoSprey-module--description--Xm1ad";
export var flexHolder = "bisolduoSprey-module--flexHolder--FL-vd";
export var flexList = "bisolduoSprey-module--flexList--rjDxS";
export var flexholder = "bisolduoSprey-module--flexholder--n7s8M";
export var holder = "bisolduoSprey-module--holder--8oeSK";
export var howHolder = "bisolduoSprey-module--howHolder--tTg22";
export var howToUseHolder = "bisolduoSprey-module--howToUseHolder--M9zbI";
export var image = "bisolduoSprey-module--image--ngVZR";
export var largeHidden = "bisolduoSprey-module--largeHidden--tURQc";
export var mobileHidden = "bisolduoSprey-module--mobileHidden--UQKRt";
export var smallHeading = "bisolduoSprey-module--smallHeading--j2vEn";
export var warningHolder = "bisolduoSprey-module--warningHolder--vZS0v";
export var whatHolder = "bisolduoSprey-module--whatHolder--r6pY8";
export var whyHolder = "bisolduoSprey-module--whyHolder--zlCj7";