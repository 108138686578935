import * as React from "react";
import * as styles from "./bisolnatur.module.scss";
import Heading from "../../basic-components/heading/heading";
import { ProductProps } from "./types";
import { Level } from "react-accessible-headings";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import { Curiosities } from "../../curiosities/curiosities";
import Button from "../../basic-components/button/button";
import { ButtonTypesEnum } from "../../basic-components/button/types";

export const Bisolnatur: React.FC<ProductProps> = ({
                                                     item,
                                                     location,
                                                   }: ProductProps) => {
  return (
    <div className={styles.holder}>
      <Heading id="nasil">Doğal içerikli öksürük şurubu Bisolnatur</Heading>
      <Level>
        <p className={styles.descriptionParagraph}>
          Doğal içerikli öksürük şurubu Bisolnatur, kuru ve balgamlı öksürüğü rahatlatır.
        </p>
        <Heading className="underline orange">Bisolnatur Nasıl Etki Eder?</Heading>
        <Level>
          <p className={styles.effectParagraph}>
            Bisolnatur tahriş olan mukoza üzerinde iki farklı şekilde mekanik
            etki sergiler.
          </p>
          <section className={classNames("flexbox", styles.effectsHolder)}>
            <div className={classNames(styles.effect)}>
              <span className="orange">1</span>
              <p>
                Mukusun nemlenmesini destekleyerek fizyolojik olarak atılmasını
                kolaylaştırır.
              </p>
            </div>
            <div className={classNames(styles.effect)}>
              <span className="orange">2</span>
              <p>
                Mukoza üzerinde bir “film” oluşturarak tahriş eden dış etkenler
                ile temasını engeller.
              </p>
            </div>
            <div className={styles.breakd} />
          </section>
          <Heading id="icerik">Bisolnatur Ürün İçeriği</Heading>
          <section className={styles.contentHolder}>
            <p>
              <span className="orange bold">Poliflav M.A. Kompleksi: </span>
              mızrak yapraklı sinirotundan elde edilen polisakkarit fraksiyonu
              ile kekikten elde edilen flavonoid fraksiyonu.
            </p>
            <p>
              <span className="orange bold">Bal: </span>Bisolnatur mukoadezif
              özellikleri olan bal ile birlikte mızrak yapraklı sinirotundan
              elde edilen polisakkarit fraksiyonu ve antioksidan etkisi ile
              mukozanın korunmasına katkıda bulunan kekikten elde edilen
              flavonoid fraksiyonundan oluşan Poliflav M.A. kompleksi sayesinde
              etki gösterir.
            </p>
          </section>
          <Heading id="ozellik">Ürün Özellikleri</Heading>
          <section className={styles.propertiesHolder}>
            <p>
              Doğal içerikli Bisolnatur aşağıdakileri{" "}
              <span className="orange bold underline">içerir</span>
            </p>
            <div className={classNames("flexbox")}>
              <StaticImage
                src="../../../images/product-pages/meyveler.svg"
                alt="Meyve Görseli"
                width={280}
                height={159}
              />
              <Level>
                <Heading className="extra-bold">
                  Doğal şeftali, limon ve portakal aromaları
                </Heading>
              </Level>
            </div>
            <p>
              Doğal içerikli Bisolnatur aşağıdakileri{" "}
              <span className="orange bold underline">içermez</span>
            </p>
          </section>
          <section className={classNames("flexbox", styles.notContainsHolder)}>
            <div className={styles.content}>
              <StaticImage
                src="../../../images/product-pages/gluten@3x.png"
                alt="Gluten Görseli"
                width={52}
                height={45}
              />
              <span className="extra-bold blue">GLUTEN</span>
            </div>
            <div className={styles.content}>
              <StaticImage
                src="../../../images/product-pages/renklendirici@3x.png"
                alt="Renklendirici Görseli"
                width={52}
                height={45}
              />
              <span className="extra-bold blue">RENKLENDİRİCİ</span>
            </div>
            <div className={styles.content}>
              <StaticImage
                src="../../../images/product-pages/koruyucu@3x.png"
                alt="Koruyucu Görseli"
                width={52}
                height={45}
              />
              <span className="extra-bold blue">KORUYUCU</span>
            </div>
            <div className={styles.content}>
              <StaticImage
                src="../../../images/product-pages/paraben@3x.png"
                alt="Paraben Görseli"
                width={52}
                height={45}
              />
              <span className="extra-bold blue">PARABEN</span>
            </div>
          </section>
          <section className={classNames("flexbox", styles.sideEffectsHolder)}>
            <StaticImage
              className={styles.image}
              src="../../../images/product-pages/cross@3x.png"
              alt="İçermez Görseli"
              width={48}
              height={42}
            />
            <p className="extra-bold">
              Bisolnatur genellikle iyi tolere edilir. Üründen kaynaklanan
              hiçbir yan etki bildirilmemiştir.
            </p>
          </section>
          <section className={styles.whyHolder}>
            <Heading>Neden Bisolnatur?</Heading>
            <div className={classNames("flexbox", styles.itemHolder)}>
              <div className={classNames("flexbox", styles.item)}>
                <StaticImage
                  className={styles.image}
                  layout="fixed"
                  src="../../../images/product-pages/yetiskinler-icin-uygundur@3x.png"
                  alt="Yeişkinler görseli"
                  width={58}
                  height={50}
                />
                <p>Yetişkinler ve 1 yaş üzeri çocuklar için uygundur.</p>
              </div>
            </div>
          </section>
          <section id="kullanim" className={styles.howToUse}>
            <Heading>Bisolnatur Nasıl Kullanılır?</Heading>
            <div className={classNames("flexbox", styles.itemHolder)}>
              <p>
                <span className="orange bold">
                  1–6 Yaş Arasındaki Çocuklar:{" "}
                </span>
                Günde iki kez doz başına 1 ölçü kaşığı (5 ml).
              </p>
              <p>
                <span className="orange bold">6 yaş üstü çocuklar: </span>
                Günde iki kez doz başına 2 ölçü kaşığı (10 ml).
              </p>
              <p>
                <span className="orange bold">Yetişkinler: </span>
                Günde iki veya üç kez doz başına 2 ölçü kaşığı (10 ml).
                Gerektiğinde, ürün kısa aralıklarla günde 4 defaya kadar
                kullanılabilir.
              </p>
            </div>
            <Level>
              <Heading className="extra-bold">UYARILAR</Heading>
              <ul>
                <li className="blue">
                  Bisolnatur Şurubu, bir ya da daha çok bileşene karşı
                  duyarlılığınız veya alerjiniz varsa ürünü kullanmayınız.
                </li>
                <li className="blue">
                  Gebelik ve emzirme sırasında doktorunuza danışarak kullanabilirsiniz.
                </li>
                <li className="blue">
                  Oda sıcaklığında, ısı ve ışıktan koruyarak saklayınız.
                </li>
                <li className="blue">
                  Çocukların ulaşamayacağı yerde saklayınız.
                </li>
                <li className="blue">
                  Son kullanma tarihi orijinal paketinde muhafaza edilen
                  açılmamış ürün için geçerlidir.
                </li>
                <li className="blue">
                  Ürünü şişeyi açtıktan 3 ay içerisinde tüketiniz.
                </li>
                <li className="blue">
                  Her ölçü kaşığı (5 ml) yaklaşık 4.1 g şeker içerir.
                </li>
              </ul>
            </Level>
          </section>
          <section
            id="merak"
            className={classNames(item.slug, styles.curiosities)}
          >
            <Heading>Merak Edilenler</Heading>
            <Curiosities item={item} showCount={4} />
            <Button
              to={location.pathname + "merak-edilenler/"}
              type={ButtonTypesEnum.navyBorder}
            >
              TÜM SORULARI GÖRÜN
            </Button>
          </section>
        </Level>
      </Level>
    </div>
  );
};

export default Bisolnatur;
