import * as React from "react";
import { ContentHolderProps } from "./types";
import { ProductStickyMenu } from "../product-sitcky-menu/product-sticky-menu";
import { Content } from "./content";

export const ContentHolder: React.FC<ContentHolderProps> = ({
  item,
  position,
  location,
  contentType,
  isStickyMenuActive,
}: ContentHolderProps) => {
  return (
    <React.Fragment>
      {isStickyMenuActive && (
        <ProductStickyMenu
          item={item}
          location={location}
          position={position}
        />
      )}
      <Content
        item={item}
        position={position}
        isStickyMenuActive={isStickyMenuActive}
        location={location}
        contentType={contentType}
      />
    </React.Fragment>
  );
};
