import React from "react";
import { Product } from "../../typings/page.types";
import { PageProps } from "gatsby";

export type ProductStickyMenuProps = React.HTMLAttributes<HTMLDivElement> & {
  item: Product;
  location: PageProps["location"];
  position: Position;
};

export enum Position {
  left = "Left",
  right = "Right",
};
