import * as React from "react";
import * as styles from "./bisolduoSurup.module.scss";
import Heading from "../../basic-components/heading/heading";
import { ProductProps } from "./types";
import { Level } from "react-accessible-headings";
import classNames from "classnames";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Button from "../../basic-components/button/button";
import { ButtonTypesEnum } from "../../basic-components/button/types";
import { Curiosities } from "../../curiosities/curiosities";

export const BisolduoSurup: React.FC<ProductProps> = ({
  item,
  location,
}: ProductProps) => {
  const {
    title,
    image: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;
  return (
    <div className={styles.holder}>
      <Heading id="neden">
        Kuru öksürüğü ve boğaz ağrısını aynı anda yatıştırır.
      </Heading>
      <Level>
        <section className={styles.whyHolder}>
          <Heading className="purple">
            Öksürük ve boğaz tahrişi neden bir arada bulunur?
          </Heading>
          <Level>
            <Heading className="extra-bold blue">
              Kuru öksürüğü olan 2 kişiden 1’inde ayrıca boğazda tahriş
              olduğunu biliyor muydunuz: Neden?<sup>1</sup>
            </Heading>
            <Level>
              <p>
                Soğuk aldığınızda, boğazınız, güneş yanığından sonra ciltte
                olduğu gibi aşırı duyarlı hale gelir. Tıpkı onun gibi, en küçük
                uyarıcılar ile dahi kaşınabilir ve öksürüğü tetikleyebilir. Bu
                semptomları azaltmak için tahriş olmuş boğazı kaplayarak ve
                nemlendirerek yatıştırmamız gerekir.<sup>2</sup>
              </p>
              <div
                className={classNames(
                  "flexbox",
                  styles.flexList,
                  styles.whyList
                )}
              >
                <div className={classNames("flexbox", styles.content)}>
                  <StaticImage
                    src="../../../images/product-pages/img-1@3x.png"
                    alt="Öksürük ve tahriş olmuş boğaz görseli"
                    width={142}
                    height={140}
                  />
                  <div className={styles.why}>
                    <Heading className="extra-bold">
                      Öksürük ve tahriş olmuş boğazın nedeni
                    </Heading>
                    <p>
                      Kuru öksürük ve tahriş olmuş bir boğaz, Rinovirüsün gibi
                      soğuk algınlığı virüslerinin neden olduğu aşırı duyarlı
                      durumdan kaynaklanır.<sup>2</sup>
                    </p>
                  </div>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <StaticImage
                    src="../../../images/product-pages/img-2@3x.png"
                    alt="İltihap görseli"
                    width={142}
                    height={140}
                  />
                  <div className={styles.why}>
                    <Heading className="extra-bold">İltihabın nedeni</Heading>
                    <p>
                      İltihap, boğazda cızırtılı, kaşıntılı bir hissi tetikleyen
                      aşırı duyarlı bir duruma neden olur.<sup>2</sup>
                    </p>
                  </div>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <StaticImage
                    src="../../../images/product-pages/img-3@3x.png"
                    alt="Kaşıntı hissi görseli"
                    width={142}
                    height={140}
                  />
                  <div className={styles.why}>
                    <Heading className="extra-bold">
                      Kaşıntı hissinin ortaya çıkışı
                    </Heading>
                    <p>
                      Rinovirüs enfeksiyonu, solunum mukozasının
                      iltihaplanmasına neden olur.<sup>2</sup>
                    </p>
                  </div>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <StaticImage
                    src="../../../images/product-pages/img-4@3x.png"
                    alt="Öksürüğünüzü tetikleyen şeyler görseli"
                    width={142}
                    height={140}
                  />
                  <div className={styles.why}>
                    <Heading className="extra-bold">
                      Öksürüğünüzü tetikleyen şeyler
                    </Heading>
                    <p>
                      Bu aşırı duyarlı durumda, soğuk hava, duman veya toz
                      boğazı daha da tahriş ederek öksürük dürtüsünü tetikler.
                      <sup>2</sup>
                    </p>
                  </div>
                </div>
              </div>
            </Level>
          </Level>
        </section>
        <Heading id="birArada" className={styles.smallHeading}>
          Gerçekten önemli anlar için 2 si 1 arada rahatlama etkisini keşfedin{" "}
        </Heading>
        <section className={styles.whatHolder}>
          <Level>
            <Heading className="purple">
              BİSOLDUO ® Şurup nedir ve ne için kullanılır?
            </Heading>
          </Level>
          <div className={classNames("flexbox", styles.flexholder)}>
            <GatsbyImage
              className={styles.image}
              loading="lazy"
              image={gatsbyImageData}
              alt={title + " ürün görseli"}
            />
            <div className={styles.description}>
              <p>
                Kuru öksürük ve boğaz ağrısı için doğal içerikli bir seçenek
                olan BİSOLDUO® Şurup;
              </p>
              <ul>
                <li className="blue">
                  Kuru öksürük belirtilerini azaltmaya ve öksürme dürtüsünü
                  yatıştırmaya yardımcı olur. Ayrıca, boğazdaki hassasiyet ve
                  kaşıntının neden olduğu kuru öksürük döngüsünü rahatlatmaya
                  yardımcıdır.
                </li>
                <li className="blue">
                  Tahriş olmuş ve iltihaplanmış ağız ve boğaz mukozasını ve
                  boğaz ağrısını rahatlatmaya yardımcı olur. Kullanım kılavuzunu
                  dikkatlice okuyunuz çünkü BİSOLDUO® Şurup ’u kullanmaya
                  başlamadan önce bilmeniz gereken önemli bilgiler içermektedir.
                </li>
              </ul>
              <span className="purple">
                Emin değilseniz veya ilave sorularınız varsa, doktorunuza veya
                eczacınıza danışınız.
              </span>
            </div>
          </div>
        </section>
        <section id="dogalFayda" className={styles.howHolder}>
          <Level>
            <Heading className="purple">
              Bisolduo Şurup nasıl etki eder?
            </Heading>
            <Level>
              <Heading className="extra-bold">
                BİSOLDUO® Şurup doğal içerikli bir yatıştırıcı, yumuşatıcı,
                rahatlatıcı olarak etki gösterir. Boğazdaki duyu reseptörlerinin
                üzerinde koruyucu bir tabaka oluşturarak öksürme dürtüsünü
                dindirmeye ve tahriş olmuş boğazınızı rahatlatmaya yardımcı
                olur.
              </Heading>
            </Level>
          </Level>
          <p>BİSOLDUO® Şurup;</p>
          <ul>
            <li className="blue">
              Tahriş olmuş ağız, yutak ve gırtlak mukozasını yatıştırarak
              boğazdaki rahatsızlığı ve kuru öksürük döngüsünü sonlandırmaya
              yardımcı olur. Böylelikle kuru öksürük belirtilerini rahatlatır ve
              öksürük dürtüsünü yatıştırır.
            </li>
            <li className="blue">
              Boğaz tahrişine neden olan dış faktörlere karşı mukozayı kaplar.
            </li>
            <li className="blue">Boğaz iltihabını yatıştırır.</li>
            <li className="blue">
              Kuru, kaşıntılı, tahriş olmuş boğaz, yutkunma zorlukları veya ses
              kısıklığı gibi boğaz rahatsızlığı semptomlarını rahatlatır.
            </li>
          </ul>
        </section>
        <section className={styles.contentHolder}>
          <Level>
            <Heading className="purple">Bisolduo Şurup’un İçeriği</Heading>
            <Level>
              <Heading className="extra-bold">
                BİSOLDUO® Şurup ; Saf doğal bal ve hatmi kökü kuru ekstresi
                (7-9:1) içerir, ekstraksiyon çözücüsü sudur.
              </Heading>
            </Level>
          </Level>
          <div className={classNames("flexbox", styles.flexList)}>
            <div className={classNames("flexbox", styles.content)}>
              <StaticImage
                className={styles.image}
                src="../../../images/product-pages/Honey@3x.png"
                alt="Öksürüğünüzü tetikleyen şeyler görseli"
                width={282}
                height={259}
              />
              <div className={styles.innerContent}>
                <Level>
                  <Level>
                    <Heading className="purple">Bal</Heading>
                  </Level>
                </Level>
                <p>
                  Bal, viskoz kıvamı ve yatıştırıcı etkileri sayesinde kuru,
                  boğaz ağrısını sakinleştirmeye yardımcı olan iyi bilinen bir
                  doğal bileşendir.
                </p>
              </div>
            </div>
            <div className={classNames("flexbox", styles.content)}>
              <StaticImage
                className={styles.image}
                src="../../../images/product-pages/MARSH@3x.png"
                alt="Öksürüğünüzü tetikleyen şeyler görseli"
                width={282}
                height={212}
              />
              <div className={styles.innerContent}>
                <Level>
                  <Level>
                    <Heading className="purple">HATMİ KÖKÜ</Heading>
                  </Level>
                </Level>
                <p>
                  Hatmi kökü, mükemmel kaplama faydaları ile yoğun kıvamlı doğal
                  bir bileşendir. Aynı zamanda, balsam etkisi yaratmaya yardımcı
                  olan, yüksek oranda çözünebilir bir bileşendir.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="onleme" className={styles.trustHolder}>
          <div className={classNames("flexbox", styles.innerContent)}>
            <div>
              <Level>
                <Heading className="purple">
                  Bisolduo Şurup güvenlik düşünülerek üretildi
                </Heading>
                <p>
                  Ailedeki herkes için, farklı yaşlardaki herkes için güvenli
                  ürünler geliştirmeye özen gösteriyoruz ve bir kişide mevcut
                  olabilecek en yaygın alerjileri daima dikkate alıyoruz.{" "}
                </p>
                <Level>
                  <Heading className="extra-bold">BİSOLDUO® Şurup;</Heading>
                </Level>
              </Level>
            </div>
            <StaticImage
              className={styles.image}
              src="../../../images/product-pages/guvenlik@3x.png"
              alt="Öksürüğünüzü tetikleyen şeyler görseli"
              width={280}
              height={280}
            />
          </div>
          <ul>
            <li className="blue">Gluten içermez</li>
            <li className="blue">Laktoz içermez</li>
            <li className="blue">Alkol içermez</li>
            <li className="blue">Yapay aroma içermez</li>
            <li className="blue">Koruyucu içermez</li>
            <li className="blue">Tadı hoştur</li>
          </ul>
        </section>
        <section className={styles.howToUseHolder}>
          <Level>
            <Heading className="purple">
              BİSOLDUO ® Şurup nasıl kullanılır?
            </Heading>
            <p>
              Bisolduo Şurup bir yemek kaşığı yardımıyla yutulabilir.
              Yetişkinler ve 2 yaş ve üzeri çocuklar için uygundur. Bu ürün
              gluten, laktoz, alkol, yapay aroma ve koruyucu madde içermez ve
              hoş bir tada sahiptir.
            </p>
            <div className={styles.caseHolder}>
              <div
                className={classNames(
                  "flexbox",
                  styles.flexHolder,
                  styles.flexList
                )}
              >
                <div className={classNames("flexbox", styles.content)}>
                  <Level>
                    <Heading>Yaş</Heading>
                  </Level>
                  <Level>
                    <Heading>Dozaj</Heading>
                  </Level>
                  <Level>
                    <Heading>Kullanım</Heading>
                  </Level>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <span className="blue">2-6</span>
                  <span className="blue">2,5 ml (yarım kaşık)</span>
                  <span className="blue">Günde en fazla 3 defa</span>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <span className="blue">6-12</span>
                  <span className="blue">5 ml (bir kaşık)</span>
                  <span className="blue">Günde en fazla 3 defa</span>
                </div>
                <div className={classNames("flexbox", styles.content)}>
                  <span className="blue">12 üstü</span>
                  <span className="blue">5 ml (bir kaşık)</span>
                  <span className="blue">Günde en fazla 3 defa</span>
                </div>
              </div>
            </div>
          </Level>
          <Button to={"/bisolduo-surup.pdf"} type={ButtonTypesEnum.navyBorder}>
            KULLANIM KILAVUZU
          </Button>
        </section>
        <section className={styles.warningHolder}>
          <Level>
            <Heading className="purple">Uyarılar</Heading>
            <ul>
              <li className="blue">
                BİSOLDUO® Şurup, 2 yaş altındaki çocuklar için uygun değildir.
              </li>
              <li className="blue">
                Bal, hatmi kökü veya BİSOLDUO® Şurup’un diğer bileşenlerinden
                herhangi birine karşı alerjikseniz (aşırı duyarlı iseniz)
                BİSOLDUO® Şurup ’u kullanmayınız.
              </li>
              <li className="blue">
                Bazı şeker içeriklerine karşı intoleransınız (hassasiyet) olduğu
                doktorunuz tarafından söylendiyse, bu tıbbi ürünü kullanmadan
                önce doktorunuza danışınız. Nadir kalıtımsal fruktoz intoleransı
                (hassasiyet) veya glukoz-galaktoz malabsorpsiyonu (emilim
                bozukluğu) olan hastalar bu ürünü kullanmamalıdır.
              </li>
              <li className="blue">
                Yeterli veri olmaması nedeniyle BİSOLDUO® Şurup ’un hamilelik ve
                emzirme döneminde kullanılması önerilmemektedir.
              </li>
              <li className="blue">
                BİSOLDUO® Şurup kısa süreli kullanım içindir ve doktora
                danışmadan 1 haftadan uzun süre kullanılmamalıdır. Semptomlar 1
                haftadan uzun devam ederse veya kötüleşirse ve ateş olması
                durumunda doktorunuza veya eczacınıza danışınız.
              </li>
              <li className="blue">
                BİSOLDUO® Şurup ’u kutu ve şişe üzerinde belirtilen son kullanma
                tarihinden sonra kullanmayınız. BİSOLDUO® Şurup ’u şişeyi
                açtıktan sonra 3 ay içinde kullanınız. Buzdolabına koymayınız ve
                25°C ‘nin üzerinde saklamayınız.
              </li>
            </ul>
          </Level>
        </section>
        <section id="sorular" className={styles.curiosities}>
          <Level>
            <Heading className="purple">Merak Edilenler</Heading>
          </Level>
          <Curiosities item={item} showCount={4} />
          <Button
            to={location.pathname + "merak-edilenler/"}
            type={ButtonTypesEnum.navyBorder}
          >
            TÜM SORULARI GÖRÜN
          </Button>
        </section>
        <section className={styles.refs}>
          <Level>
            <Level>
              <Heading className="purple">REFERANSLAR</Heading>
            </Level>
          </Level>
          <p id="nereden">
            1. U&A 2017 DE ES IT MX RU, IPSOS.
            <br />
            2. Atkinson SK et al. BMJ Open Respir Res. 2016; 3(1): e000118.
            <br />
            3. https://www.medicinenet.com/how_to_stop_coughing/article.htm
            <br />
            4. Wikihow https://www.wikihow.com/Stop-Coughing-at-Night /<br />
          </p>
        </section>
      </Level>
    </div>
  );
};

export default BisolduoSurup;
