import * as React from "react";
import { useContext } from "react";
import { ContentProps } from "./types";
import * as contentStyles from "./content.module.scss";
import classNames from "classnames";
import { AppContext } from "../../app-provider";
import Bisolnatur from "./product/bisolnatur";
import BisolduoSurup from "./product/bisolduoSurup";
import BisolduoSprey from "./product/bisolduoSprey";
import BisolviralSprey from "./product/bisolviralSprey";

export const Content: React.FC<ContentProps> = ({
  item,
  position,
  contentType,
  isStickyMenuActive,
  location,
}: ContentProps) => {
  const { className } = item;
  const appContext = useContext(AppContext);

  return (
    <div
      className={classNames(
        "content-holder",
        "flexbox",
        contentStyles.holder,
        contentStyles[`position${position}`],
        isStickyMenuActive ? contentStyles.withStickyMenu : ""
      )}
    >
      <div
        className={classNames(
          contentStyles.menuHolder,
          appContext.scrollY >= 340 && isStickyMenuActive
            ? contentStyles.stick
            : ""
        )}
      >
        <br />
      </div>
      <div
        className={classNames(
          contentStyles.contentHolder,
          appContext.scrollY >= 340 && isStickyMenuActive
            ? contentStyles.stick
            : ""
        )}
      >
        <div className={contentStyles.content}>
          {contentType === "product" && className === "bisolnatur" && (
            <Bisolnatur item={item} location={location} />
          )}
          {contentType === "product" && className === "bisolduoSurup" && (
            <BisolduoSurup item={item} location={location} />
          )}
          {contentType === "product" && className === "bisolduoSprey" && (
            <BisolduoSprey item={item} location={location} />
          )}
          {contentType === "product" && className === "bisolviralSprey" && (
            <BisolviralSprey item={item} location={location} />
          )}
        </div>
      </div>
    </div>
  );
};
