import * as React from "react";
import Layout from "../../components/layout/layout";
import { ProductHeader } from "../../components/product-header/product-header";
import { graphql } from "gatsby";
import { ProductPageProps } from "../../typings/page.types";
import { ContentHolder } from "../../components/content/content-holder";
import { Position } from "../../components/product-sitcky-menu/types";
import {Testimonials} from "../../components/testimonials/testimonials";
import {Helmet} from "react-helmet";

const ProductPageComp: React.FC<ProductPageProps> = ({
  data: { productsJson },
  location,
}: ProductPageProps) => {
  return (
    <Layout
      stickyHeader
      seoConfig={{
        title: productsJson.seoTitle,
        description: productsJson.seoDescription,
      }}
      // additionalComponentsToRender={(productsJson.slug === "bisolnatur" ? <Testimonials limitByPriority={4} orderByPriority={true}/> : undefined)}
    >
        <Helmet>
            <script type="application/ld+json">
                {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Ürünler","item": "https://www.bisolnatur.com.tr/urunler/"},
                  {"@type": "ListItem","position": 3,"name": "${productsJson.title}","item": "https://www.bisolnatur.com.tr/urunler/${productsJson.slug}"}
                ]
            }
          `}
            </script>
        </Helmet>
      <ProductHeader item={productsJson} location={location} />
      <ContentHolder
        item={productsJson}
        location={location}
        isStickyMenuActive={true}
        contentType="product"
        position={Position.left}
      />
    </Layout>
  );
};
export default ProductPageComp;

export const query = graphql`
  query ($id: String!) {
    productsJson(id: { eq: $id }) {
      title
      id
      properties
      className
      logo {
        publicURL
      }
      whiteLogo {
        publicURL
      }
      sections {
        inner
        path
        text
      }
      curiosities {
        title
        text
      }
      headerImage {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            breakpoints: [360, 768, 1200]
            placeholder: NONE
          )
        }
      }
      image {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            breakpoints: [360, 768, 1200]
            placeholder: NONE
          )
        }
      }
      seoDescription
      seoTitle
      slug
    }
  }
`;
