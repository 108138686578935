// extracted by mini-css-extract-plugin
export var breakd = "bisolnatur-module--breakd--TpEQX";
export var content = "bisolnatur-module--content--k0zSn";
export var contentHolder = "bisolnatur-module--contentHolder--V7tK-";
export var curiosities = "bisolnatur-module--curiosities--EMQic";
export var descriptionParagraph = "bisolnatur-module--descriptionParagraph--qiVH1";
export var effect = "bisolnatur-module--effect--YPijN";
export var effectParagraph = "bisolnatur-module--effectParagraph--7v+3c";
export var effectsHolder = "bisolnatur-module--effectsHolder--KXnqD";
export var holder = "bisolnatur-module--holder--wRA2b";
export var howToUse = "bisolnatur-module--howToUse--5hQAt";
export var image = "bisolnatur-module--image--aJOGQ";
export var item = "bisolnatur-module--item--okpdm";
export var itemHolder = "bisolnatur-module--itemHolder--vwxH3";
export var notContainsHolder = "bisolnatur-module--notContainsHolder--lff6d";
export var propertiesHolder = "bisolnatur-module--propertiesHolder--cgQni";
export var sideEffectsHolder = "bisolnatur-module--sideEffectsHolder--GsZPr";
export var whyHolder = "bisolnatur-module--whyHolder--ktxuE";