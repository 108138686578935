import * as React from "react";
import { useContext } from "react";
import { ProductStickyMenuProps } from "./types";
import * as productStickyMenuStyles from "./product-sticky-menu.module.scss";
import classNames from "classnames";
import Sticky from "react-stickynode";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../basic-components/button/button";
import { ButtonTypesEnum } from "../basic-components/button/types";
import { AppContext } from "../../app-provider";

export const ProductStickyMenu: React.FC<ProductStickyMenuProps> = ({
  item,
  location,
  position,
}: ProductStickyMenuProps) => {
  const {
    title,
    headerImage: {
      childImageSharp: { gatsbyImageData },
    },
  } = item;
  const { pathname } = location;
  const appContext = useContext(AppContext);
  console.log(item.className);
  const kilavuz =
    item.className === "bisolnatur"
      ? "/bisolnatur-kk.pdf"
      : item.className === "bisolduoSurup"
        ? "/bisolduo-surup.pdf"
        : item.className === "bisolviralSprey"
          ? "/bisolviralsprey-kk.pdf"
          : "";
  return (
    <Sticky
      top={120}
      enableTransforms={false}
      innerZ={4}
      bottomBoundary=".bottomBoundary"
    >
      <div
        className={classNames(
          productStickyMenuStyles[item.className],
          productStickyMenuStyles.menu,
          productStickyMenuStyles[`position${position}`],
          appContext.scrollY >= 340 ? productStickyMenuStyles.stick : ""
        )}
      >
        <div className={productStickyMenuStyles.content}>
          <GatsbyImage
            className={productStickyMenuStyles.image}
            loading="lazy"
            image={gatsbyImageData}
            alt={title + " ürün görseli"}
          />
          <div className={productStickyMenuStyles.buttonHolder}>
            <Button
              type={ButtonTypesEnum.navyBorder}
              to={"/nereden-satin-alabilirim/"}
            >
              NEREDEN SATIN ALIRIM
            </Button>
            <Button type={ButtonTypesEnum.navyBorder} to={pathname.replace("nasil-etki-eder/", "") + "fiyat/"}>
              FİYATLAR
            </Button>
                <Button type={ButtonTypesEnum.navyBorder} to={kilavuz}>
                  KULLANIM KILAVUZU
                </Button>
          </div>
        </div>
      </div>
    </Sticky>
  );
};
