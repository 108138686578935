// extracted by mini-css-extract-plugin
export var caseHolder = "bisolduoSurup-module--caseHolder--jyWHG";
export var content = "bisolduoSurup-module--content--G+4NY";
export var contentHolder = "bisolduoSurup-module--contentHolder--qKMqv";
export var curiosities = "bisolduoSurup-module--curiosities--pnpHy";
export var description = "bisolduoSurup-module--description--gS+-l";
export var flexHolder = "bisolduoSurup-module--flexHolder--PJ0hW";
export var flexList = "bisolduoSurup-module--flexList--tcdbh";
export var flexholder = "bisolduoSurup-module--flexholder--BSABi";
export var holder = "bisolduoSurup-module--holder--E0tpJ";
export var howHolder = "bisolduoSurup-module--howHolder--Z9nBA";
export var howToUseHolder = "bisolduoSurup-module--howToUseHolder--Vd3Wh";
export var image = "bisolduoSurup-module--image--2AlaX";
export var innerContent = "bisolduoSurup-module--innerContent--xZyXW";
export var refs = "bisolduoSurup-module--refs--HPqpd";
export var smallHeading = "bisolduoSurup-module--smallHeading--RHD2V";
export var trustHolder = "bisolduoSurup-module--trustHolder--aDmB6";
export var warningHolder = "bisolduoSurup-module--warningHolder--5rX70";
export var whatHolder = "bisolduoSurup-module--whatHolder--bim4S";
export var why = "bisolduoSurup-module--why--Yi8FE";
export var whyHolder = "bisolduoSurup-module--whyHolder--v3R+e";
export var whyList = "bisolduoSurup-module--whyList--rw3E4";