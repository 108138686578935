// extracted by mini-css-extract-plugin
export var bisolduoSprey = "product-sticky-menu-module--bisolduoSprey--t6yxA";
export var bisolduoSurup = "product-sticky-menu-module--bisolduoSurup--0B7g1";
export var bisolnatur = "product-sticky-menu-module--bisolnatur--Wsmn6";
export var bisolviralSprey = "product-sticky-menu-module--bisolviralSprey--I1TCV";
export var buttonHolder = "product-sticky-menu-module--buttonHolder--Eg0Ix";
export var content = "product-sticky-menu-module--content--bAqrg";
export var image = "product-sticky-menu-module--image--kdKT+";
export var menu = "product-sticky-menu-module--menu--4LiOM";
export var positionLeft = "product-sticky-menu-module--positionLeft--qHuQP";
export var positionRight = "product-sticky-menu-module--positionRight--j0X2G";
export var stick = "product-sticky-menu-module--stick--r36Z+";